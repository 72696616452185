import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import fileSaver from "file-saver";

import imgLogo from "./assets/images/logo.svg";
import imgBanner from "./assets/images/banner.svg";
import imgFooter from "./assets/images/footer.svg";
import imgTime from "./assets/images/time.svg";
import imgDownload from "./assets/images/download.png";

const cloudfrontUrl = "https://d23jhf5i1h83qm.cloudfront.net";

function Download() {
  const [fileExists, setFileExists] = useState<boolean | null>(null);

  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  let { videoId } = useParams<{ videoId: string }>();

  useEffect(() => {
    setVideoUrl(`${cloudfrontUrl}/${videoId}.mp4`);
  }, [videoId]);

  function checkFileExists(url: string) {
    return fetch(url, {
      method: "HEAD", // file isn't downloaded to the client while checking if it exists
    })
      .then((response) => {
        if (response.ok) {
          console.log("File exists.");
          setFileExists(true);
        } else {
          console.log("File does not exist.");
          setFileExists(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setFileExists(false);
      });
  }

  useEffect(() => {
    if (!videoUrl) return;

    console.log("Checking if the file exists...");

    checkFileExists(videoUrl)
      .then()
      .catch((error) => console.error(error));
  }, [videoUrl]);

  const saveVideo = useCallback(() => {
    if (!videoUrl || !fileExists) return;

    fileSaver.saveAs(videoUrl, "JoyOfMoving.mp4");
  }, [videoUrl, fileExists]);

  return (
    // <div className="App">
    //   <header className="App-header">
    //     {fileExists !== null &&
    //       (fileExists ? (
    //         <button onClick={saveVideo}>Save video</button>
    //       ) : (
    //         <p>The video is still uploading...</p>
    //       ))}
    //   </header>
    // </div>

    <div className="wrapper">
      <div className="banner">
        <img src={imgBanner} className="banner-image" alt="banner" />
        <img src={imgLogo} className="logo" alt="logo" />
      </div>
      <div className="content-holder">
        <div className="content">
          <div className="title">Your moment on the big stage</div>
          <div className="center">
            {fileExists !== null &&
              (fileExists && videoUrl ? (
                // video player
                <video className="video" controls>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={imgTime} className="time-image" alt="time" />
              ))}
          </div>
          <div className="bottom">
            <div className="description">
              {fileExists !== null &&
                (fileExists && videoUrl
                  ? "Download your moment on the big stage to show your friends and family"
                  : "Your Video is being prepared, check back later to download")}
            </div>
            <div>
              <button
                className="download-button"
                style={fileExists ? {} : { opacity: 0.2 }}
                onClick={saveVideo}
              >
                Download
                <img src={imgDownload} alt="download" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <img src={imgFooter} className="footer-image" alt="footer" />
    </div>
  );
}

export default Download;
